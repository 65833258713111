import Service from './_base';
import axios from 'axios';
import dataService from '../services/data';
import { capitalize } from 'lodash-es';

const { CountrySelection } = dataService;

class GoogleService extends Service {
    async getZipCoordinates(zip, country) {
        let response = await axios.get(
            'https://maps.googleapis.com/maps/api/geocode/json?address=' +
            zip +
            ',' +
            country +
            '&key=' +
            window.env?.REACT_APP_GOOGLE_MAPS_GEOCODE_API_KEY
        );
        return response.data;
    }

    async getAddressFromCoordinates(lat, lng) {
        let response = await axios.get(
            'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            lat +
            ',' +
            lng +
            '&key=' +
            window.env?.REACT_APP_GOOGLE_MAPS_GEOCODE_API_KEY
        );
        return response.data;
    }

    async getPlacesAutocomplete(zip, country) {
        let config = { secure: false };
        let response = await axios.get(
            'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=' +
            country +
            ',' +
            zip +
            '&key=' +
            window.env?.REACT_APP_GMAPS_API_KEY,
            config
        );
        return response.data;
    }

    async getMapCoordinates(address, countryIds) {
        if (address) {
            let url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURIComponent(address),
                countriesList = [];

            if (countryIds) {
                countryIds.forEach((id) => {
                    let country = CountrySelection.find((item) => item.id === id);

                    if (country && country.name) {
                        countriesList.push('country:' + encodeURIComponent(capitalize(country.name)));
                    }
                });

                if (countriesList.length > 0) {
                    url += '&components=' + countriesList.join('|');
                }
            }

            url += '&key=' + window.env?.REACT_APP_GOOGLE_MAPS_GEOCODE_API_KEY;

            let response = await axios.get(url);

            return response && response.data ? response.data : false;
        }
    }
}

export default new GoogleService();
