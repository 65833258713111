const SourceList = {
    EstateList: 1,
    ContactList: 2,
    EstateAdd: 3,
    EstatePublish: 4,
    EstateMatching: 5,
    CalendarList: 6,
    ContactAdd: 7,
    ContactAdded: 8,
    CalendarSearch: 9,
    EmailSettings: 10,
    SendEmail: 11,
    EmailList: 12,
    EstateDocuments: 13,
    CalendarEdit: 14,
    CalendarAdd: 15,
    MediaPublication: 16,
    PublicationDetails: 17,
    EstateActivityList: 18,
    HistoryAdd: 19,
    HistoryEdit: 20
};
export default SourceList;
