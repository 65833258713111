import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { UserStatus, OfficeStatus, SubdetailType, SubdetailDataType, AgencyCategoryType, EstateSubdetail } from 'commons';
import Features from './features';
import { find, forEach, filter, isEmpty, includes, pick, sortBy, reject, last, first, map, orderBy, trim } from '../lib/lodash';
import { idealTextColor, toFullName, generateGuid, removeDiacritics, getBrowserInfo } from '../lib/utils';
import { handleInterceptorResponseError } from '../lib/httpUtils';
import { LicenseType } from '../m/base/enums';
import { removeLocalStorage } from '../m/hooks/useLocalStorage';
import { dataService } from '../services';
import i18n from '../lib/i18n';

class SelectionBuilder {
    static getMLValue(list, languageId, name = 'name', skipFirst = false) {
        if (!list) {
            return '';
        }
        languageId = languageId.toLowerCase();
        let itemLanguage = find(list, (x) => x.languageId.toLowerCase() === languageId);
        if (itemLanguage) {
            return itemLanguage[name];
        }
        if (!skipFirst) {
            let firstItem = first(list);
            if (firstItem) {
                return first(list)[name];
            }
        }
        return '';
    }

    static getSelection(results, languageId, addDefault = false, defaultText = '', orderByIndex = false) {
        languageId = languageId.toLowerCase();

        let selection = [];
        if (orderByIndex) {
            results = sortBy(results, (x) => x.orderIndex);
        }
        forEach(results, (result) => {
            let item = {};
            item.id = result.id;
            item.color = result.color;
            if (result.nameML) {
                let itemLanguage = find(result.nameML, (x) => x.languageId.toLowerCase() === languageId);
                item.name = itemLanguage ? itemLanguage.name : first(result.nameML).name;
            }
            selection.push(item);
        });
        if (!orderByIndex) {
            selection = sortBy(selection, (x) => x.name);
        }
        if (addDefault) {
            selection.splice(0, 0, this.getEmptyItem(defaultText));
        }
        return selection;
    }

    static getSelectionList(results, languageId, sortByName = true) {
        let selection = [];
        forEach(results, (result) => {
            let item = {};
            item.id = result.id;
            item.name = '';
            if (result.nameML) {
                item.name = this.getMLValue(result.nameML, languageId);
            }
            item.color = result.color;
            selection.push(item);
        });
        if (sortByName) {
            selection = sortBy(selection, (x) => removeDiacritics(x.name).toLowerCase());
        }
        return selection;
    }

    static getRegionSelectionList(results, languageId, sortByName = true) {
        let selection = [];
        forEach(results, (result) => {
            let item = {};
            item.id = result.id;
            item.name = '';
            if (result.nameML) {
                item.name = this.getMLValue(result.nameML, languageId);
            }
            if (result.cityML) {
                item.name += ' [' + this.getMLValue(result.cityML, languageId) + ']';
            }
            item.color = result.color;
            selection.push(item);
        });
        if (sortByName) {
            selection = sortBy(selection, (x) => removeDiacritics(x.name).toLowerCase());
        }
        return selection;
    }

    static getContactTypeSelection(results, languageId, addDefault = false, defaultText = '') {
        languageId = languageId.toLowerCase();

        let selection = [];
        forEach(results, (result) => {
            let item = {};
            item.id = result.id;
            item.color = result.color;
            if (result.nameML) {
                let itemLanguage = find(result.nameML, (x) => x.languageId.toLowerCase() === languageId);
                item.name = itemLanguage ? itemLanguage.name : first(result.nameML).name;
            }
            selection.push(item);
        });
        selection = sortBy(selection, (x) => x.name);
        if (addDefault) {
            selection.splice(0, 0, this.getEmptyItem(defaultText));
        }
        return selection;
    }

    static getContactTitleSelection(results, languageId, addDefault = false, defaultText = '') {
        languageId = languageId.toLowerCase();
        results = orderBy(results, ['id'], ['asc']);

        let selection = [];
        forEach(results, (result) => {
            let item = {};
            item.id = result.id;
            if (result.shortDescriptionML) {
                let itemLanguage = find(result.shortDescriptionML, (x) => x.languageId.toLowerCase() === languageId);
                item.name = itemLanguage ? itemLanguage.name : first(result.shortDescriptionML).name;
            }
            selection.push(item);
        });

        if (addDefault) {
            selection.splice(0, 0, this.getEmptyItem(defaultText));
        }
        return selection;
    }

    static getLocalizableSelection(results, languageId, addDefault = false, defaultText = '', orderByName = true) {
        languageId = languageId.toLowerCase();

        let selection = [];
        map(results, (value, key) => {
            let item = {};
            item.id = parseInt(key.toString());
            let itemLanguage = find(value, (x) => x.languageId.toLowerCase() === languageId);
            item.name = itemLanguage ? itemLanguage.name : first(value).name;
            selection.push(item);
        });
        if (orderByName) {
            selection = sortBy(selection, (x) => x.name);
        }
        if (addDefault) {
            selection.splice(0, 0, this.getEmptyItem(defaultText));
        }
        return selection;
    }

    static getLanguageSelection(results) {
        let selection = [];
        forEach(results, (result) => {
            let item = {};
            item.id = result.id;
            item.name = result.name;
            selection.push(item);
        });
        selection = sortBy(selection, (x) => x.name);
        return selection;
    }

    static getCalendarActionSelection(results, languageId) {
        languageId = languageId.toLowerCase();
        forEach(results, (result) => {
            result.orderIndex = result.orderIndex || 0;
        });
        results = orderBy(results, ['orderIndex', 'id'], ['asc', 'asc']);

        let selection = [];
        forEach(results, (result) => {
            let item = {};
            item.id = result.id;
            item.color = result.color;
            if (result.descriptionML) {
                let itemLanguage = find(result.descriptionML, (x) => x.languageId.toLowerCase() === languageId);
                item.name = itemLanguage ? itemLanguage.name : first(result.descriptionML).name;
            } else {
                item.name = '';
            }
            item.orderIndex = result.orderIndex;
            selection.push(item);
        });

        return selection;
    }

    static getEstateGroupSelection(results) {
        let selection = [];
        forEach(results, (result) => {
            let item = {};
            item.id = result.id;
            item.name = result.name;
            item.parentId = result.projectId;
            selection.push(item);
        });

        selection = sortBy(selection, (x) => x.name);

        return selection;
    }

    static getActiveOffices = (offices) => {
        let activeOffices = filter(offices, (x) => x.statusId === OfficeStatus.Normal);

        let officeSelection = [];
        forEach(activeOffices, (office) => {
            officeSelection.push({
                id: office.id,
                name: office.name
            });
        });

        return officeSelection;
    };

    static getPurposeStatusSelection(results, languageId, addDefault = false, defaultText = '') {
        languageId = languageId.toLowerCase();

        let selection = [];
        forEach(results, (result) => {
            let item = {};
            item.id = result.id;
            item.parentId = result.purposeId;
            let itemLanguage = find(result.nameML, (x) => x.languageId.toLowerCase() === languageId);
            if (result.nameML) {
                itemLanguage = find(result.nameML, (x) => x.languageId.toLowerCase() === languageId);
                item.name = itemLanguage ? itemLanguage.name : first(result.nameML).name;
            }
            selection.push(item);
        });
        selection = sortBy(selection, (x) => x.name);
        if (addDefault) {
            selection.splice(0, 0, this.getEmptyItem(defaultText));
        }
        return selection;
    }

    static getSubCategorySelection(results, languageId, addDefault = false, defaultText = '') {
        languageId = languageId.toLowerCase();

        let selection = [];
        forEach(results, (result) => {
            let item = {};
            item.id = result.id;
            item.parentId = result.categoryId;
            let itemLanguage = find(result.nameML, (x) => x.languageId.toLowerCase() === languageId);
            item.name = itemLanguage ? itemLanguage.name : first(result.nameML).name;
            selection.push(item);
        });
        selection = sortBy(selection, (x) => x.orderIndex);
        if (addDefault) {
            selection.splice(0, 0, this.getEmptyItem(defaultText));
        }
        return selection;
    }

    static getSubdetailEnumSelection(results, languageId, sort = false) {
        languageId = languageId.toLowerCase();

        let selection = this.getEmptySelection(false);
        forEach(results, (result) => {
            let item = {};
            item.id = result.id;

            let itemLanguage = find(result.nameML, (x) => x.languageId.toLowerCase() === languageId);
            if (itemLanguage) {
                item.name = itemLanguage.name;
                if (item.name && item.name !== ' - ' && item.name !== '-') {
                    selection.push(item);
                }
            }
        });

        if (sort !== false) {
            selection = sortBy(selection, (x) => x.name);
        }

        return selection;
    }

    static getEmptySelection(addDefault = false, defaultText = '') {
        return addDefault ? [{ id: null, name: defaultText || '---------', color: '#FFFFFF' }] : [];
    }
}

class EstateUtils {
    static getSubdetailUnit(name, subdetailTypeId, measurementUnits, currencySymbol) {
        let unit = '';

        switch (subdetailTypeId) {
            case SubdetailType.Volume:
                unit = measurementUnits[2];
                break;

            case SubdetailType.Surface:
                unit = measurementUnits[1];
                break;

            case SubdetailType.Price:
            case SubdetailType.PriceSigned:
                unit = currencySymbol;
                break;

            case SubdetailType.Length:
                unit = measurementUnits[0];
                break;

            case SubdetailType.Liter:
                unit = 'l';
                break;

            case SubdetailType.Float:
            case SubdetailType.Int:
                let re = /\((.*)\)/gi;
                let unitMatch = re.exec(name);
                if (unitMatch) {
                    unit = unitMatch[1];
                    name = name.replace(re, '').trim();
                }
                break;

            default:
                unit = '';
                name = '';
        }

        return {
            name,
            unit
        };
    }

    static getSubdetailDataType(subdetailId, subdetailTypeId, name) {
        if (
            subdetailId === EstateSubdetail.Keys ||
            subdetailId === EstateSubdetail.Summons ||
            subdetailId === EstateSubdetail.ParcellingPermission ||
            subdetailId === EstateSubdetail.RightOfPreemption ||
            subdetailId === EstateSubdetail.Intimation ||
            subdetailId === EstateSubdetail.BuildingPermission ||
            subdetailId === EstateSubdetail.Rented ||
            subdetailId === EstateSubdetail.AdvertisingPanelAuthorized ||
            subdetailId === EstateSubdetail.AdvertisingPanelPlaced ||
            subdetailId === EstateSubdetail.Servitude ||
            subdetailId === EstateSubdetail.AppliedVAT ||
            subdetailId === EstateSubdetail.GroundCertificate ||
            subdetailId === EstateSubdetail.AsBuilt ||
            subdetailId === EstateSubdetail.AccessForPeopleWithHandicap ||
            subdetailId === EstateSubdetail.Elevator ||
            subdetailId === EstateSubdetail.Pool
        ) {
            return SubdetailDataType.NullableYesNo;
        }

        if (name === 'evaluation') {
            return SubdetailDataType.Evaluation;
        } else {
            switch (subdetailTypeId) {
                case SubdetailType.Undefined:
                    return SubdetailDataType.Undefined;

                case SubdetailType.Text:
                case SubdetailType.Hour:
                case SubdetailType.AdminInfo:
                case SubdetailType.Invariant:
                    return SubdetailDataType.Text;
                case SubdetailType.DateTime:
                    return SubdetailDataType.Date;

                case SubdetailType.Float:
                case SubdetailType.Int:
                case SubdetailType.Year:
                case SubdetailType.Length:
                case SubdetailType.Surface:
                case SubdetailType.Price:
                case SubdetailType.Liter:
                case SubdetailType.Volume:
                case SubdetailType.IntSigned:
                case SubdetailType.FloatSigned:
                case SubdetailType.PriceSigned:
                    return SubdetailDataType.Numeric;

                case SubdetailType.YesNo:
                    return SubdetailDataType.Boolean;

                case SubdetailType.AutoComplete:
                    return SubdetailDataType.AutoComplete;

                case SubdetailType.LetterEnergyIndexDPE_FR:
                case SubdetailType.LetterEnergyIndexGES_FR:
                    return SubdetailDataType.EnumColored;

                default:
                    return SubdetailDataType.Enum;
            }
        }
    }
}

export default class InitVm {
    constructor() {
        this._userProfile = dataService;
    }

    static initializeCulture(languageId) {
        localStorage.removeItem('i18next_res_en-GB-translation');
        if (languageId !== 'en-GB') {
            localStorage.removeItem(`i18next_res_${languageId}-translation`);
        }

        i18n.changeLanguage(languageId);
    }

    static initializeReferenceData(userProfile, data) {
        if (!isEmpty(data.calendarActions)) {
            forEach(data.calendarActions, (calendarAction) => {
                if (calendarAction.color.length > 7) {
                    calendarAction.color = '#' + calendarAction.color.substring(3);
                }
                calendarAction.textColor = idealTextColor(calendarAction.color);
            });
            userProfile.CalendarActions = data.calendarActions;
        }
        if (!isEmpty(data.logFields)) {
            userProfile.AuditFields = data.logFields;
        }
        if (!isEmpty(data.contactTitles)) {
            userProfile.ContactTitles = data.contactTitles;
        }
        if (!isEmpty(data.contactTypes)) {
            userProfile.ContactTypes = data.contactTypes;
        }
        if (!isEmpty(data.languages)) {
            userProfile.Languages = data.languages;
        }
        if (!isEmpty(data.users)) {
            forEach(data.users, (user) => {
                if (user.statusId === UserStatus.Deleted) {
                    user.permissions = [];
                }

                user.fullName = toFullName(user.firstName, user.name);
            });
            userProfile.Users = data.users;
        }
        if (!isEmpty(data.offices)) {
            userProfile.Offices = data.offices;
        }
        if (!isEmpty(data.contactStatuses)) {
            userProfile.ContactStatuses = data.contactStatuses;
        }
        if (!isEmpty(data.estateStatuses)) {
            userProfile.EstateStatuses = data.estateStatuses;
        }
        if (!isEmpty(data.displayStatuses)) {
            userProfile.DisplayStatuses = data.displayStatuses;
        }
        if (!isEmpty(data.prospectionStatuses)) {
            userProfile.ProspectionStatuses = data.prospectionStatuses;
        }
        if (!isEmpty(data.maritalStatuses)) {
            userProfile.MaritalStatuses = data.maritalStatuses;
        }
        if (!isEmpty(data.contactOrigins)) {
            userProfile.ContactOrigins = data.contactOrigins;
        }
        if (!isEmpty(data.countries)) {
            userProfile.Countries = data.countries;
        }
        if (!isEmpty(data.purposes)) {
            userProfile.Purposes = data.purposes;
        }
        if (!isEmpty(data.purposeStatuses)) {
            userProfile.PurposeStatuses = data.purposeStatuses;
        }
        if (!isEmpty(data.categories)) {
            userProfile.Categories = data.categories;
        }
        if (!isEmpty(data.subCategories)) {
            userProfile.Subcategories = data.subCategories;
        }
        if (!isEmpty(data.regions)) {
            userProfile.Regions = data.regions;
        }
        if (!isEmpty(data.states)) {
            userProfile.States = data.states;
        }
        if (!isEmpty(data.contactNumberTypes)) {
            userProfile.ContactNumberTypes = data.contactNumberTypes;
        }
        if (!isEmpty(data.details)) {
            userProfile.Details = data.details;
        }
        if (!isEmpty(data.subDetails)) {
            userProfile.Subdetails = data.subDetails;
        }
        if (!isEmpty(data.subDetailEnums)) {
            userProfile.SubdetailEnums = data.subDetailEnums;
        }
        if (!isEmpty(data.logCategories)) {
            userProfile.HistoryCategories = data.logCategories;
        }
        if (!isEmpty(data.availabilities)) {
            userProfile.Availabilities = data.availabilities;
        }
        // if (!isEmpty(data.medias)) {
        //     forEach(filter(data.medias, (x: MediaModel) => !x.serviceConsumerTypeId), (x) => {
        //         x.serviceConsumerTypeId = ServiceConsumerType.WebsiteMedia;
        //         x.membershipTypeId = MembershipType.Basic;
        //     });
        //     userProfile.Medias = data.medias;
        // }
        // if (!isEmpty(data.mediaLanguages)) {
        //     userProfile.MediaLanguages = data.mediaLanguages;
        // }
        // if (!isEmpty(data.externalAccounts)) {
        //     userProfile.ExternalAccounts = data.externalAccounts;
        // }
        if (!isEmpty(data.mediaSettings)) {
            userProfile.MediaSettings = data.mediaSettings;
        }
        if (!isEmpty(data.exportMediaStatuses)) {
            userProfile.ExportMediaStatuses = data.exportMediaStatuses;
        }
        if (!isEmpty(data.baseDocumentTypes)) {
            userProfile.BaseDocumentTypes = data.baseDocumentTypes;
        }
        if (!isEmpty(data.contractStatuses)) {
            userProfile.ContractStatuses = data.contractStatuses;
        }
        if (!isEmpty(data.contractTypes)) {
            userProfile.ContractTypes = data.contractTypes;
        }
        if (!isEmpty(data.estateGroups)) {
            userProfile.EstateGroups = data.estateGroups;
        }
        if (!isEmpty(data.baseContactTypes)) {
            userProfile.BaseContactTypes = data.baseContactTypes;
        }
    }

    static initializeProfile(userProfile, preferences, languageId) {
        userProfile.CountrySelection = SelectionBuilder.getSelectionList(userProfile.Countries, languageId);
        userProfile.RegionSelection = SelectionBuilder.getRegionSelectionList(userProfile.Regions, languageId);
        userProfile.ContactTypeSelection = SelectionBuilder.getContactTypeSelection(userProfile.ContactTypes, languageId);
        userProfile.ContactTitleSelection = SelectionBuilder.getContactTitleSelection(userProfile.ContactTitles, languageId);
        userProfile.ContactStatusSelection = SelectionBuilder.getLocalizableSelection(
            userProfile.ContactStatuses,
            languageId,
            false,
            '',
            false
        );
        userProfile.EstateStatusSelection = SelectionBuilder.getLocalizableSelection(
            userProfile.EstateStatuses,
            languageId,
            false,
            '',
            false
        );
        userProfile.LanguageSelection = filter(SelectionBuilder.getLanguageSelection(userProfile.Languages), (x) =>
            includes(userProfile.UsedLanguages, x.id)
        );
        userProfile.ProspectionStatusSelection = SelectionBuilder.getSelection(
            userProfile.ProspectionStatuses,
            languageId,
            false,
            '',
            false
        );
        userProfile.ContactOriginSelection = SelectionBuilder.getSelection(
            userProfile.ContactOrigins,
            languageId,
            false,
            '',
            false
        );
        userProfile.MaritalStatusSelection = SelectionBuilder.getLocalizableSelection(userProfile.MaritalStatuses, languageId);
        userProfile.ActionSelection = SelectionBuilder.getCalendarActionSelection(userProfile.CalendarActions, languageId);
        userProfile.HistoryCategorySelection = SelectionBuilder.getLocalizableSelection(
            userProfile.HistoryCategories,
            languageId,
            false
        );
        userProfile.StateSelection = SelectionBuilder.getSelectionList(userProfile.States, languageId, false);
        userProfile.AvailabilitySelection = SelectionBuilder.getLocalizableSelection(
            userProfile.Availabilities,
            languageId,
            false
        );

        let filteredDisplayStatuses = reject(userProfile.DisplayStatuses, (x) => x.id === 5);
        userProfile.DisplayStatusSelection = SelectionBuilder.getSelectionList(filteredDisplayStatuses, languageId);

        userProfile.DetailsWithPictureSelection = SelectionBuilder.getSelectionList(
            filter(userProfile.Details, (x) => x.picture === true),
            languageId
        );
        userProfile.ContractTypeSelection = SelectionBuilder.getLocalizableSelection(
            userProfile.ContractTypes,
            userProfile.LanguageId
        );
        userProfile.ContractStatusSelection = SelectionBuilder.getLocalizableSelection(
            userProfile.ContractStatuses,
            userProfile.LanguageId
        );
        userProfile.BaseDocumentTypeSelection = SelectionBuilder.getSelection(userProfile.BaseDocumentTypes, languageId);
        userProfile.EstateGroupSelection = SelectionBuilder.getEstateGroupSelection(userProfile.EstateGroups);
        userProfile.BaseContactTypeSelection = SelectionBuilder.getSelection(userProfile.BaseContactTypes, languageId);

        let activeUsers = filter(userProfile.Users, (x) => x.statusId === UserStatus.Active);
        activeUsers = orderBy(activeUsers, [(x) => (x.fullName ? trim(x.fullName.toLowerCase()) : '')], ['asc']);
        let userSelection = [];

        forEach(activeUsers, (user) => {
            userSelection.push({
                id: user.id,
                name: user.fullName,
                officeId: user.officeId
            });
        });

        userProfile.ActiveUsers = userSelection;
        userProfile.ActiveOffices = SelectionBuilder.getActiveOffices(userProfile.Offices);

        let purposes = sortBy(
            SelectionBuilder.getLocalizableSelection(userProfile.Purposes, languageId, false, null, false),
            (x) => x.id
        );
        let purposeStatuses = sortBy(
            SelectionBuilder.getPurposeStatusSelection(userProfile.PurposeStatuses, languageId, false),
            (x) => x.id
        );
        userProfile.PurposeSelection = purposes;
        userProfile.PurposeStatusSelection = purposeStatuses;

        let categories = sortBy(SelectionBuilder.getLocalizableSelection(userProfile.Categories, languageId, false), (x) => x.id);
        let subcategories = sortBy(
            SelectionBuilder.getSubCategorySelection(userProfile.Subcategories, languageId, false),
            (x) => x.id
        );
        userProfile.CategorySelection = categories;
        userProfile.SubcategorySelection = subcategories;

        let purposeStatusSelection = [];
        forEach(purposes, (x) => {
            let groupVm = {};
            groupVm.id = x.id;
            groupVm.name = x.name;
            groupVm.options = filter(purposeStatuses, (y) => y.parentId === x.id);
            purposeStatusSelection.push(groupVm);
        });
        userProfile.PurposeStatusGroupSelection = purposeStatusSelection;

        let subcategorySelection = [];
        forEach(categories, (x) => {
            let groupVm = {};
            groupVm.id = x.id;
            groupVm.name = x.name;
            groupVm.options = filter(subcategories, (y) => y.parentId === x.id);
            subcategorySelection.push(groupVm);
        });
        userProfile.SubcategoryGroupSelection = subcategorySelection;

        let details = userProfile.Details;
        let subdetails = userProfile.Subdetails;
        let enums = userProfile.SubdetailEnums;
        let measurementUnits = preferences.measurementUnits ? preferences.measurementUnits.split(',') : ['m', 'm²'];
        userProfile.CurrencySymbol = preferences.currencySymbol;

        let subdetailSelection = [];
        forEach(subdetails, (subdetail) => {
            if (!subdetail.nameML) {
                subdetail.nameML = [];
            }
            let subdetailVm = {};
            subdetailVm.id = subdetail.id;
            subdetailVm.detailId = subdetail.detailId;
            subdetailVm.subdetailTypeId = subdetail.subdetailTypeId;

            let detail = details[subdetail.detailId];
            if (detail && detail.nameML) {
                subdetailVm.detailname = SelectionBuilder.getMLValue(detail.nameML, languageId);
            }

            let nameUnit = EstateUtils.getSubdetailUnit(
                SelectionBuilder.getMLValue(subdetail.nameML, languageId, 'name'),
                subdetail.subdetailTypeId,
                measurementUnits,
                userProfile.CurrencySymbol
            );
            subdetailVm.name = nameUnit.name;
            subdetailVm.unit = nameUnit.unit;

            subdetailVm.type = EstateUtils.getSubdetailDataType(
                subdetail.id,
                subdetail.subdetailTypeId,
                SelectionBuilder.getMLValue(subdetail.nameML, 'en-GB')
            );
            if (subdetailVm.type === SubdetailDataType.Enum) {
                let subdetailEnums = filter(enums, (x) => x.subdetailId === subdetail.id);
                subdetailVm.values = SelectionBuilder.getSubdetailEnumSelection(
                    subdetailEnums,
                    languageId,
                    subdetailVm.subdetailTypeId !== SubdetailType.Day
                );
            }

            subdetailVm.label = SelectionBuilder.getMLValue(subdetail.nameML, languageId, 'label');
            if (!subdetailVm.label) {
                subdetailVm.label = subdetailVm.detailname || '';

                if (subdetailVm.type === SubdetailDataType.Text && subdetailVm.label.indexOf(subdetailVm.name) < 0) {
                    subdetailVm.label += ' - ' + subdetailVm.name;
                }
            }
            // if (subdetailVm.label) {
            //     var re = /\((.*)\)/gi;
            //     var unitMatch = re.exec(subdetailVm.label);
            //     if (unitMatch) {
            //         if (!subdetailVm.unit) {
            //             subdetailVm.unit = unitMatch[1];
            //         }
            //         subdetailVm.label = subdetailVm.label.replace(re, '').trim();
            //     }
            // }

            subdetailSelection[subdetail.id] = subdetailVm;
        });

        userProfile.SubdetailSelection = subdetailSelection;

        userProfile.MaxPictureResolution = last(preferences.pictureFolders);
        userProfile.IsCorporateClient = preferences.agencyCategoryType === AgencyCategoryType.Offices;
        userProfile.EnableExperimentalFeatures =
            preferences.enableExperimentalFeatures && preferences.enableExperimentalFeatures === true;

        // if (userProfile.loadMedias) {
        //     userProfile.loadMedias(preferences.mediaVisibility);
        // }

        userProfile.Notifications = null;
        userProfile.Preferences = pick(preferences, [
            'useCalendarBackgroundColors',
            'calendarDefaultUserIds',
            'calendarViewMode',
            'calendarDayStartHour',
            'calendarDayEndHour',
            'calendarSlotDuration',
            'nameFormat',
            'listLayout',
            'reportingContactEmailPreferences',
            'reportingEstateEmailPreferences',
            'reportingEstateDocumentPreferences',
            'reportingOwnerEmailPreferences',
            'reportingMatchingEmailPreferences',
            'argusApiKey',
            'emailAccountConfiguration',
            'emailConfiguration',
            'usedLanguages',
            //'estateDetailsConfiguration',
            'mediaDetailsConfiguration',
            'manualMatchingParameters',
            'listFilters',
            'permissionType',
            'allowContactRepresentativeChangeByAnyUser',
            'blockCreateUpdateDelete',
            'websiteNotSearchAnymoreBehavior',
            'unsubscribeGeneralMailsBehavior',
            'unsubscribeAutomaticMatchingBehavior',
            'showCostCalculation',
            'exportMediaScoreRules',
            'documentFontsAndColors',
            'contactNameInCapitalLetters',
            'watermarkPreferences',
            'automaticallyCloseOnSave',
            'contactFormatPhoneNumber',
            'dashboardData',
            'enableTelephony',
            'massMailing',
            'displayPriceNewEstates',
            'displayAddressNewEstates',
            'marketingBooster',
            'estateRepresentativeAsEmailSender',
            'mobileActivation',
            'emailReader',
            'readOnlyMode',
            'mediaVisibility',
            'calendarViewGroupBy',
            'automaticallyAddBCCSendEmail',
            'calendarDefaultAppointmentDuration',
            'smsCreditByOffice',
            'smtpServerConfig',
            'measurementUnits',
            'currencySymbol',
            'pictureFolders',
            'agencyCategoryType',
            'enableExperimentalFeatures',
            'sendEmailToAllAddressesOfContact',
            'features',
            'defaultTemplateSelection',
            'searchOnline',
            'websiteActivation',
            'personalPageConfigurationForRent',
            'personalPageConfigurationForSale'
        ]);

        if (!userProfile.InstanceId) {
            userProfile.InstanceId = generateGuid();
        }
    }

    getUserProfile = async (token, languageId) => {
        return new Promise(async (resolve, reject) => {
            let client = axios.create({
                baseURL: window.env?.REACT_APP_API_URL,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            let profile = (await trackPromise(client.get('clients/profile'))).data;
            if (!profile || !profile.userId) {
                reject();
                return;
            }

            if (this._userProfile.ClientId !== profile.clientId) {
                this._userProfile.ReferenceDataLastUpdateDate = '';
            }

            this._userProfile.UserId = profile.userId;
            this._userProfile.OfficeId = profile.officeId;
            this._userProfile.ClientId = profile.clientId;
            this._userProfile.StoragePath = profile.storagePath;
            this._userProfile.StorageUrl = profile.storageUrl;
            this._userProfile.ServerId = profile.serverId;
            this._userProfile.ClientName = profile.clientName;
            this._userProfile.ClientStatusId = profile.clientStatusId;
            this._userProfile.UserStatusId = profile.userStatusId;
            this._userProfile.IsDomainAtWhise = profile.isDomainAtWhise !== undefined;
            this._userProfile.Email = profile.email;
            this._userProfile.CountryId = profile.countryId;
            this._userProfile.Name = profile.name;
            this._userProfile.NameOutgoingMail = profile.nameOutgoingMail;
            this._userProfile.OfficeName = profile.officeName;
            this._userProfile.Url = profile.url;
            this._userProfile.DetailUrl = profile.detailUrl;
            this._userProfile.ContactUrl = profile.contactUrl;
            this._userProfile.PrivacyUrl = profile.privacyPageUrl;
            this._userProfile.Permissions = profile.permissions;
            this._userProfile.ComChannels = profile.comChannels;
            this._userProfile.AgreeTerms = profile.agreeTerms;
            this._userProfile.GroupInfo = profile.groupInfo;
            this._userProfile.IsGroupMember = profile.groupInfo !== undefined;
            let preferences = profile.preferences;

            /*
                RoleCategory.GeneralAdministrator is 3
                RoleCategory.DistributorAdministrator is 4
                RoleCategory.Administrator is 2
                DistributorAdministrator include GeneralAdministrator
                Administrator include GeneralAdministrator & DistributorAdministrator
            */
            this._userProfile.IsAdministrator = profile.permissions.some(
                (x) => x.roleId === 2 || x.roleId === 3 || x.roleId === 4
            );

            let usedLanguages = preferences.usedLanguages || [];
            let referenceData = (
                await client.get('references', {
                    params: {
                        searchParameters: {
                            languageIdList: usedLanguages,
                            lastUpdateDate: this._userProfile.ReferenceDataLastUpdateDate
                        },
                        includes:
                            ',logcategory,enum,detail,subdetail,contactnumbertype,purpose,category,region,state,subcategory,country,maritalstatus,contactorigin,prospectionstatus,contactstatus,action,contacttitle,contacttype,language,memoaction,office,user,estatestatus,displaystatus,availability,exportmediastatus,basedocumenttype,contracttype,contractstatus,estategroup,logfield,mediasettings,'
                    }
                })
            ).data;

            let profileLanguageSelection =
                referenceData.result.languages && preferences.usedLanguages
                    ? filter(SelectionBuilder.getLanguageSelection(referenceData.result.languages), (x) =>
                        includes(preferences.usedLanguages, x.id)
                    ).map((x) => x.id)
                    : null;
            languageId = languageId || profile.languageId;

            let languageSelection = profileLanguageSelection || ['fr-BE', 'nl-BE', 'en-GB', 'ro-RO', 'tr-TR', 'es-ES'];
            if (languageSelection.indexOf(languageId) < 0) {
                switch (languageId) {
                    case 'fr-CA':
                    case 'fr-FR':
                        languageId = 'fr-BE';
                        break;

                    case 'nl-NL':
                        languageId = 'nl-BE';
                        break;

                    default:
                        languageId = 'en-GB';
                        break;
                }
            }
            this._userProfile.LanguageId = languageId;

            if (usedLanguages.indexOf(languageId) < 0) {
                usedLanguages.push(languageId);
            }
            this._userProfile.UsedLanguages = usedLanguages;
            InitVm.initializeCulture(languageId);

            InitVm.initializeReferenceData(this._userProfile, referenceData.result);

            InitVm.initializeProfile(this._userProfile, preferences, languageId);

            this._userProfile.Features = new Features(profile.preferences, profile.officeId);
            this._userProfile.ReferenceDataLastUpdateDate = referenceData.queryInfo.syncDateTime;

            this._userProfile.EstateSubdetails = (await client.get('admin/estatesubdetails?mergeActivatedSubdetails=true')).data;
            this._userProfile.ServiceConsumers = (await client.get('clients/consumers')).data;
            // await this._userProfile.loadTelephonySettings(this._userProfile.OfficeId, preferences.telephonySettings);

            //set isFreemium
            this._userProfile.IsFreemium = false;
            let accountInfo = (await client.get(`clients/account/info`, null)).data;
            if (!accountInfo) {
                // this._userProfile.IsFreemium = false;
                this._userProfile.AllowPayment = false;
                this._userProfile.PackageType = null;
                this._userProfile.DataLayerPackageType = null;
            } else {
                this._userProfile.DataLayerPackageType = accountInfo.packageType;
                if (accountInfo.isAccountForUpgrade) {
                    this._userProfile.PackageType = accountInfo.packageType;
                    if (accountInfo.products && Object.keys(accountInfo.products).length > 1) {
                        this._userProfile.AllowPayment = true;
                    }
                    //PackageType.WhiseFreemium
                    if (accountInfo.packageType === LicenseType.WhiseFreemium) {
                        this._userProfile.IsFreemium = true;
                    }
                    //PackageType.WhiseBasic
                    if (accountInfo.packageType === LicenseType.WhiseBasic) {
                        let pref = preferences;
                        pref.marketingBooster = false;
                        preferences = pref;
                    }
                }
            }

            if (this._userProfile.ServiceConsumers) {
                let sc = find(this._userProfile.ServiceConsumers, (x) => x.clientId === profile.clientId);
                if (sc) {
                    this._userProfile.ServiceConsumerId = sc.id;
                    this._userProfile.ServiceConsumerTypeId = sc.serviceConsumerTypeId;
                } else {
                    this._userProfile.ServiceConsumerId = null;
                    this._userProfile.ServiceConsumerTypeId = null;
                }
            }
            resolve();
        });
    };

    getAppId = () => {
        let browserInfo = getBrowserInfo();
        const platform = navigator.userAgentData?.platform || navigator.userAgent || '';
        return 'mobile-old: ' + platform + '-' + browserInfo.name + browserInfo.version;
    };

    refreshAccessToken = async () => {
        const client = axios.create({
            baseURL: window.env?.REACT_APP_API_URL.replace('api/v2', '')
        });

        const body = {
            appid: this.getAppId(),
            apiVersion: '2.8.8'
        };

        let response = await client.post('/AccessToken/Refresh', body, { withCredentials: true });

        if (!response?.data) {
            return null;
        }

        const { access_token, access_token_expires } = response.data;

        localStorage.setItem('AccessToken', access_token);
        localStorage.setItem('AccessTokenExpires', access_token_expires);

        return access_token;
    };

    loginByCredentials = async (params) => {
        let calcAppId = this.getAppId();
        let client = axios.create({
            baseURL: window.env?.REACT_APP_API_URL.replace('api/v2', '')
        });
        let response = null;

        try {
            response = await client.post(
                '/AccessToken',
                `grant_type=password&username=${encodeURIComponent(params.username)}&password=${encodeURIComponent(
                    params.password
                )}&appid=${calcAppId}&apiver=2.8.8`,
                { withCredentials: true }
            );
        } catch (e) {
            return handleInterceptorResponseError(e, true);
        }

        if (!response?.data) {
            return 'UnexpectedError';
        }

        const { access_token, access_token_expires } = response.data;

        let profileLoaded = false;
        try {
            await this.getUserProfile(access_token, params.languageId);
            profileLoaded = true;
        } catch (e) {
            console.error('Error loading user profile');
        }
        if (!profileLoaded) {
            return 'UnexpectedError';
        }

        localStorage.setItem('AccessToken', access_token);
        localStorage.setItem('AccessTokenExpires', access_token_expires);
        localStorage.setItem('Username', params.username);
        localStorage.setItem('Password', params.password);

        //necessary?
        removeLocalStorage('EmailAccessToken');
        removeLocalStorage('EmailAccessTokenExpireDate');
        removeLocalStorage('OriginalData');
        removeLocalStorage('OnboardingCompleted');
    };

    signUpLoginByCredentialsToken = async (params) => {
        let calcAppId = this.getAppId();
        let client = axios.create({
            baseURL: window.env?.REACT_APP_API_URL.replace('api/v2', '')
        });

        let response = await trackPromise(
            client.post(
                '/token',
                `grant_type=password&username=${encodeURIComponent(params.username)}&password=${params.password
                }&appid=${calcAppId}&apiver=2.8.8`
            )
        );

        let token = response.data.access_token;

        await trackPromise(this.getUserProfile(token, params.languageId));

        this._userProfile.Username = params.username;
        this._userProfile.Password = params.password;
        this._userProfile.AccessToken = token;
        localStorage.setItem('AccessToken', token);
        removeLocalStorage('OriginalData');
        removeLocalStorage('OnboardingCompleted');
    };
}
