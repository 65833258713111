import axios from 'axios';

let token = localStorage.getItem('EmailAccessToken');
let options = {
    baseURL: window.env.REACT_APP_EMAIL_API_URL
};

if (token) {
    options.headers = {
        Authorization: `Bearer ${token}`
    };
}

const client = axios.create(options);

client.interceptors.response.use(null, (error) => {
    if (error?.response?.status === 401) {
        let currentToken = localStorage.getItem('EmailAccessToken');
        localStorage.removeItem('EmailAccessToken');
        localStorage.removeItem('EmailAccessTokenExpireDate');
        // if there was a token which exipred, then redirect back to the emails, to refresh the token
        if (currentToken) {
            window.location.reload();
        } else {
            window.location = window.env.REACT_APP_ENV !== 'prd' ? '/#/' : '/#/app/';
        }
    } else if (error?.response?.status === 403) {
        return error.response;
    }
});

export default client;
