import { RoleCategory, RoleItemType, RoleLevel, Module } from 'commons';
import { getDefaultSelectionForPicker } from '../lib/utils';
import dataService from './data';

export default class Security {
    constructor(permissions, currentUserId, permissionType, allowContactRepresentativeChangeByAnyUser, blockCed) {
        this.permissions = permissions;
        this.currentUserId = currentUserId;
        this.permissionType = permissionType || new PermissionTypeModel();
        this.isGeneralAdministrator = this.permissions.filter((x) => x.roleId === RoleCategory.GeneralAdministrator).length > 0;
        this.isDistributorAdministrator =
            this.isGeneralAdministrator ||
            this.permissions.filter((x) => x.roleId === RoleCategory.DistributorAdministrator).length > 0;
        this.isAdministrator =
            this.isDistributorAdministrator || this.permissions.filter((x) => x.roleId === RoleCategory.Administrator).length > 0;
        this.isOfficeAdministrator = this.permissions.filter((x) => x.roleId === RoleCategory.OfficeAdministrator).length > 0;
        this.isBackOffice = this.permissions.filter((x) => x.roleId === RoleCategory.BackOffice);
        this.allowContactRepresentativeChangeByAnyUser = allowContactRepresentativeChangeByAnyUser;
        this.blockCed = blockCed;
        this.minBlockLevel = RoleLevel.ReadOnly;
    }

    /**
     * filter the given items for the provided module and role level
     * @param {array} items array of objects to filter, they will be filtered by id
     * @param {number} module module for which to filter
     * @param {number} roleLevel role level that the user needs to have
     * @returns
     */
    filterItemsWithAccess = (items, module, roleLevel) => {
        let filteredItems = [];
        if (!items || items.length === 0) {
            return filteredItems;
        }
        let allowedItemsIds = this.getItemsWithAccess(module, roleLevel);
        if (allowedItemsIds) {
            filteredItems = items.filter((x) => allowedItemsIds.includes(x.value));
        } else if (!allowedItemsIds) {
            filteredItems = items;
        }
        return filteredItems;
    };

    getItemsWithAccess = (module, level) => {
        var ids = null;

        if (this.blockCed && level > this.minBlockLevel) {
            return [];
        }

        switch (module) {
            case Module.Administration:
                if (
                    !this.isAdministrator &&
                    this.permissions.filter((x) => x.roleId === RoleCategory.OfficeAdministrator && x.itemId).length > 0
                ) {
                    ids = this.permissions
                        .filter((x) => x.roleId === RoleCategory.OfficeAdministrator && x.itemId && x.levelId >= level)
                        .map((x) => x.itemId);
                }
                break;

            case Module.Estate:
                if (
                    !this.isAdministrator &&
                    this.permissions.filter(
                        (x) => x.roleId === RoleCategory.Estate && x.itemId && x.itemTypeId === this.permissionType.estate
                    ).length > 0
                ) {
                    ids = this.permissions
                        .filter(
                            (x) =>
                                x.roleId === RoleCategory.Estate &&
                                x.itemId &&
                                x.itemTypeId === this.permissionType.estate &&
                                x.levelId >= level
                        )
                        .map((x) => x.itemId);

                    if (this.permissionType.estate === RoleItemType.User && !ids.includes(this.currentUserId)) {
                        ids.push(this.currentUserId);
                    }
                }
                break;

            case Module.Contact:
                if (
                    !this.isAdministrator &&
                    this.permissions.filter(
                        (x) => x.roleId === RoleCategory.Contact && x.itemId && x.itemTypeId === this.permissionType.contact
                    ).length > 0
                ) {
                    ids = this.permissions
                        .filter(
                            (x) =>
                                x.roleId === RoleCategory.Contact &&
                                x.itemId &&
                                x.itemTypeId === this.permissionType.contact &&
                                x.levelId >= level
                        )
                        .map((x) => x.itemId);

                    // eslint-disable-next-line
                    if (this.permissionType.contact == RoleItemType.User && !ids.includes(this.currentUserId)) {
                        ids.push(this.currentUserId);
                    }
                }
                break;

            case Module.Company:
                if (
                    !this.isAdministrator &&
                    this.permissions.filter(
                        (x) => x.roleId === RoleCategory.Company && x.itemId && x.itemTypeId === this.permissionType.company
                    ).length > 0
                ) {
                    ids = this.permissions
                        .filter(
                            (x) =>
                                x.roleId === RoleCategory.Company &&
                                x.itemId &&
                                x.itemTypeId === this.permissionType.company &&
                                x.levelId >= level
                        )
                        .map((x) => x.itemId);
                }
                break;

            case Module.Calendar:
                if (
                    !this.isAdministrator &&
                    this.permissions.filter(
                        (x) => x.roleId === RoleCategory.Calendar && x.itemId && x.itemTypeId === this.permissionType.calendar
                    ).length > 0
                ) {
                    ids = this.permissions
                        .filter(
                            (x) =>
                                x.roleId === RoleCategory.Calendar &&
                                x.itemId &&
                                x.itemTypeId === this.permissionType.calendar &&
                                x.levelId >= level
                        )
                        .map((x) => x.itemId);

                    if (this.permissionType.calendar === RoleItemType.User && !ids.includes(this.currentUserId)) {
                        ids.push(this.currentUserId);
                    }
                }
                break;

            case Module.Memo:
                if (
                    !this.isAdministrator &&
                    this.permissions.filter(
                        (x) => x.roleId === RoleCategory.Memo && x.itemId && x.itemTypeId === this.permissionType.memo
                    ).length > 0
                ) {
                    ids = this.permissions
                        .filter(
                            (x) =>
                                x.roleId === RoleCategory.Memo &&
                                x.itemId &&
                                x.itemTypeId === this.permissionType.memo &&
                                x.levelId >= level
                        )
                        .map((x) => x.itemId);

                    if (this.permissionType.memo === RoleItemType.User && !ids.includes(this.currentUserId)) {
                        ids.push(this.currentUserId);
                    }
                }
                break;

            default:
                break;
        }
        return ids;
    };

    hasPermission = (role, level, itemId, itemTypeId) => {
        var permission = this.permissions.find(
            (x) => x.roleId === role && x.itemId && x.itemId === itemId && x.itemTypeId === itemTypeId
        );
        if (permission == null) {
            return true;
        }

        return permission.levelId >= level;
    };

    hasAccessToItem = (module, level, itemId) => {
        // administrator has access to all items
        if (this.isAdministrator) {
            return true;
        }

        switch (module) {
            case Module.Estate:
                if (this.permissionType.estate === RoleItemType.User && itemId === this.currentUserId) {
                    return true;
                }
                return this.hasPermission(RoleCategory.Estate, level, itemId, this.permissionType.estate);

            case Module.Contact:
                if (this.permissionType.contact === RoleItemType.User && itemId === this.currentUserId) {
                    return true;
                }
                return this.hasPermission(RoleCategory.Contact, level, itemId, this.permissionType.contact);

            case Module.Company:
                if (this.permissionType.company === RoleItemType.User && itemId === this.currentUserId) {
                    return true;
                }
                return this.hasPermission(RoleCategory.Company, level, itemId, this.permissionType.company);

            case Module.Calendar:
                if (this.permissionType.calendar === RoleItemType.User && itemId === this.currentUserId) {
                    return true;
                }
                return this.hasPermission(RoleCategory.Calendar, level, itemId, this.permissionType.calendar);

            case Module.Memo:
                if (this.permissionType.memo === RoleItemType.User && itemId === this.currentUserId) {
                    return true;
                }
                return this.hasPermission(RoleCategory.Memo, level, itemId, this.permissionType.memo);

            case Module.Order:
                return this.hasPermission(RoleCategory.Order, level, itemId, RoleItemType.Office);

            default:
        }

        return false;
    };

    hasAccess = (module, level, itemIds) => {
        if (!level && !itemIds) {
            return this.hasAccessToModule(module);
        }

        if (this.blockCed && level > this.minBlockLevel) {
            return false;
        }

        // administrator has access to all items
        if (this.isAdministrator) {
            return true;
        }
        for (var i = 0; i < itemIds.length; i++) {
            if (this.hasAccessToItem(module, level, itemIds[i])) {
                return true;
            }
        }
        return false;
    };

    hasAccessToAllItems = (module, level, itemIds) => {
        if (!level && !itemIds) {
            return this.hasAccessToModule(module);
        }

        if (this.blockCed && level > this.minBlockLevel) {
            return false;
        }

        // administrator has access to all items
        if (this.isAdministrator) {
            return true;
        }
        for (var i = 0; i < itemIds.length; i++) {
            if (!this.hasAccessToItem(module, level, itemIds[i])) {
                return false;
            }
        }
        return true;
    };

    getContactRepresentativesWithAccess = (existingRepresentativeIds) => {
        if (
            this.isAdministrator ||
            this.isOfficeAdministrator ||
            this.allowContactRepresentativeChangeByAnyUser === 1 ||
            typeof this.allowContactRepresentativeChangeByAnyUser == 'undefined'
        ) {
            // Yes
            return null;
        }

        if (!existingRepresentativeIds) {
            existingRepresentativeIds = [];
        }

        switch (this.allowContactRepresentativeChangeByAnyUser) {
            case 0: // No
                return existingRepresentativeIds.includes(this.currentUserId) ? [this.currentUserId] : [];

            case -1: // No but yes if no representative
                if (existingRepresentativeIds.length === 0) {
                    return null;
                }
                return existingRepresentativeIds.includes(this.currentUserId) ? [this.currentUserId] : [];

            default:
                return [];
        }
    };

    /**
     * function that returns allowed users and offices for given module and role level
     * @param {number} module module for which to filter
     * @param {number} roleLevel role level that the user needs to have
     * @param {boolean} forPicker true is the returned selections need to be formatted for picker
     *
     * @returns {isAllowed, allowedOffices, allowedUsers }
     */
    getAllowedItems = (module, roleLevel, forPicker) => {
        let allowedIds = this.getItemsWithAccess(module, roleLevel);
        let allowedOffices = [];
        let allowedUsers = [];
        let isAllowed = false;
        if (allowedIds) {
            if (this.permissionType.contact === RoleItemType.User) {
                allowedUsers = Object.values(dataService.ActiveUsers).filter((x) => allowedIds.includes(x.id));
                allowedOffices = Object.values(dataService.ActiveOffices).filter((x) =>
                    allowedUsers.find((y) => y.officeId === x.id)
                );
            } else {
                allowedOffices = Object.values(dataService.ActiveOffices).filter((x) => allowedIds.includes(x.id));
                allowedUsers = Object.values(dataService.ActiveUsers).filter((x) => allowedIds.includes(x.officeId));
            }
        } else {
            allowedOffices = dataService.ActiveOffices;
            allowedUsers = dataService.ActiveUsers;
        }
        if (allowedUsers.length || allowedOffices.length) {
            isAllowed = true;
        }
        if (forPicker) {
            allowedOffices = getDefaultSelectionForPicker(allowedOffices);
            allowedUsers = getDefaultSelectionForPicker(allowedUsers);
        }
        return { isAllowed, allowedOffices, allowedUsers };
    };

    /**
     * Function that returns allowed users and allowed offices depending given RoleLevel and RoleItemType (set per client)
     *
     * @param {RoleLevel} roleLevel
     * @memberof SecurityManager
     * @returns {allowedOffices, allowedUsers}
     */
    getEstateAllowedItems = (roleLevel) => {
        let allowedIds = this.getItemsWithAccess(Module.Estate, roleLevel);
        let allowedOffices = [];
        let allowedUsers = [];

        if (allowedIds) {
            if (this.permissionType.estate == RoleItemType.User) {
                allowedUsers = Object.values(dataService.ActiveUsers).filter((x) => allowedIds.includes(x.id));
                allowedOffices = Object.values(dataService.ActiveOffices).filter((x) =>
                    allowedUsers.find((y) => y.officeId === x.id)
                );
            } else {
                allowedOffices = Object.values(dataService.ActiveOffices).filter((x) => allowedIds.includes(x.id));
                allowedUsers = Object.values(dataService.ActiveUsers).filter((x) => allowedIds.includes(x.officeId));
            }
        } else {
            allowedOffices = dataService.ActiveOffices;
            allowedUsers = dataService.ActiveUsers;
        }
        return { allowedOffices, allowedUsers };
    };

    areContactRightsPer = (roleItemType) => {
        return dataService.Security.permissionType.contact === roleItemType;
    };

    areEstateRightsPer = (roleItemType) => {
        return dataService.Security.permissionType.estate === roleItemType;
    };
}

class PermissionTypeModel {
    constructor() {
        this.calendar = RoleItemType.User;
        this.contact = RoleItemType.Office;
        this.company = RoleItemType.Office;
        this.estate = RoleItemType.Office;
        this.memo = RoleItemType.User;
    }
}
