import Logger from "../services/logger";
import { IsProduction } from "./utils";
import i18n from '../lib/i18n';
import { validateEstateResult } from "../m/Estate/EstateValidationUtil";
import moment from "moment";

const MyLogger = new Logger(
    {
        lingerMs: 0
    },
    null
);

const clearOverflow = () => {
    setTimeout(() => {
        if (document.body.style.overflow) {
            document.body.style.removeProperty('overflow');
        }
    }, 100);
};

const formatItemForValidation = (item, config) => {
    return {
        location: window.location.pathname + window.location.hash,
        baseUrl: config.baseURL,
        url: config.url,
        data: config.data,
        code: item.code,
        property: item.property,
        resource: item.resource
    };
};

const formatAPIErrorMessages = (errors) => {
    let messages = [];
    let errorMessage;
    for (let error of errors) {
        if (error.message.indexOf('V_') >= 0) {
            errorMessage = i18n.t(error.message).replace('{PropertyName}', i18n.t(error.property));
        } else {
            errorMessage = error.message;
        }
        messages.push(errorMessage);
    }
    return messages;
};

export const handleStatusError401 = (error, isLogin = false) => {
    let errorCode = error.response.data?.errorCode;
    let redirectTo = '';
    let isError = false;
    switch (errorCode) {
        //can happen only on login
        case 'InvalidLoginAttempt':
        case 'AccountLocked':
            break;

        case 'ExpiredPassword':
            redirectTo = `https://web${!IsProduction ? 'stg' : ''}.whise.eu/forgot-password.html`;
            break;

        case 'TokenExpired':
        case 'InvalidTokenChangedPassword':
        case 'NotAllowedLocation':
        case 'NotAllowedIP':
            redirectTo = '#/login';
            break;

        case 'InvalidTokenSecurityStampChanged':
        default:
            redirectTo = '#/login';
            errorCode = 'UnauthorizedAccess';
            isError = true;
            break;
    }

    if (isLogin) {
        if (redirectTo && window.location.hash !== redirectTo) {
            //the user needs to see the error message first
            setTimeout(() => {
                window.location = redirectTo;
            }, 2000)
        }
        return `AUTH_${errorCode}`;
    }

    //logger not available on login page
    if (isError) {
        MyLogger.error(errorCode, error.response);
    } else {
        MyLogger.warn(errorCode, error.response.data);
    }

    if (redirectTo && window.location.hash !== redirectTo) {
        window.location = redirectTo;
    }

}

export const handleInterceptorResponseRequest = (response) => {
    if (response.config.url === 'estates/savedata') {
        const mediaErrors = validateEstateResult(response.data);
        if (mediaErrors) {
            throw mediaErrors;
        }
    }
    if (response.data && response.data.errors && response.data.errors.length > 0) {
        response.data.errors.forEach((item) => {
            MyLogger.warn('Validation from API', formatItemForValidation(item, response.config));
        });
        throw formatAPIErrorMessages(response.data.errors);
    }
    if (response.data && response.data.warnings && response.data.warnings.length > 0) {
        response.data.warnings.forEach((item) => {
            MyLogger.warn('Validation from API', formatItemForValidation(item, response.config));
        });
    }
    clearOverflow();
    return response;
}

export const handleInterceptorResponseError = (error, isLogin = false) => {
    let errorMessage = '';
    if (error && error.response) {
        switch (error.response.status) {
            case 400:
                MyLogger.warn('Invalid request', error.response);
                return error.response;
            case 401:
                errorMessage = handleStatusError401(error, isLogin);
                break;

            case 500:
                MyLogger.warn('Internal Server Error', error.response);
                window.location = '/#/internalservererror';
                break;

            default:
                MyLogger.error('Unhandled from API', error.response);
                break;
        }
    }
    if (isLogin) {
        return errorMessage;
    }
}

export const checkAppVersion = () => {
    const appVersion = localStorage.getItem('Version');
    if (appVersion !== window.env.REACT_APP_VERSION) {
        localStorage.clear();
        window.location.href = '/login';
    }
};

// Function to check if token is expired
export const isTokenExpired = () => {
    if (localStorage.getItem('AccessTokenLastUpdate')) {
        return true;
    }
    const expireTime = localStorage.getItem('AccessTokenExpires');
    if (!expireTime) {
        return true;
    }
    // Calculate the difference in hours
    if (moment().isAfter(moment(expireTime))) {
        return true;
    }
    return false;
};