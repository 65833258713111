import Service from './_base';
import { trackPromise } from 'react-promise-tracker';

class TemplateService extends Service {
    /**
     * get the list of templates;
     * this function retrieves the info from the files stored in db
     * @param params: object with optional values: { templateItemIds, typeIds, names, statusIds, formats, extensions, clientIds, includeWhiseTemplates )
     *      templateItemIds : Array<int>
     *      typeIds: Array<TemplateType>
     *      names: Array<string> names of the templates to retrieve
     *      statusIds: Array<int> |
     *      formats: Array<string> | valid formats: ['A4', 'A3']
     *      extensions: Array<string> |  valid extensions: ['tpl', 'tplx']
     *      clientIds: Array<int>
     *      includeWhiseTemplates: boolean;  if true, take also the templates from the distributor templates (whise templates);
     * @returns {Promise<*>} : <SearchResponseData> { responseData, totalCount,aggregates, searchId }
     *                  ResponseData : Array<TemplateItemModel>
     *                  TotalCount : int
     *                  Aggregates: Array<AggregateBucket>
     *                  SearchId : int
     */
    async getTemplateItemFiles(params) {
        let url = 'templateitems/search';
        let searchParams = {
            aggregate: null,
            query: params,
            page: {
                index: 0,
                size: 3000
            }
        };

        let response = await trackPromise(this.client.post(url, searchParams));
        return response.data && response.data.responseData ? response.data.responseData : null;
    }

    /**
     * get a list of templates with content;
     * this function retrieves the info from the files stored in db + the content of the templates
     * @param params: object with optional values: { ids, encoding, fromTemplate }
     *      ids : Array<int>
     *      encoding: string : default is UTF-8
     *      fromTemplate: boolean;  if true, read the templates from the distributor templates (whise templates);
     *      getMJML : boolean; if true, return the mjml content instead of the tplx content
     * @returns {Promise<*>} : an Array<TemplateItemWithContentModel>

     */
    async getTemplateItemFilesWithContent(params) {
        let url = 'templateitems/getwithcontent';
        let response = await trackPromise(this.client.post(url, params));
        return response && response.data && response.data && response.data.length > 0 ? response.data[0] : null;
    }

    async emailTemplateProcess(param, attachments = [], correlationId = null) {
        let fd = new FormData();
        fd.append('request', JSON.stringify(param));

        let i = 0;
        for (let attachment of attachments) {
            fd.append('att' + i.toString(), attachment);
            i++;
        }

        this.client.defaults.baseURL = window.env.REACT_APP_EMAIL_API_URL;
        let response = await this.client.post('emails/process', fd, {
            params: {
                correlationId: correlationId
            }
        });
        this.client.defaults.baseURL = window.env.REACT_APP_API_URL;
        if (!response) {
            return null;
        }
        if (response.status === 400 || response.status === 403) {
            return response;
        } else {
            let responseData = response && response.data;
            if (!responseData) {
                responseData = { status: response.status };
            }
            return responseData;
        }
    }
}

export default new TemplateService();
