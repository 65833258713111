import axios from 'axios';
import { handleInterceptorResponseRequest, handleInterceptorResponseError, checkAppVersion, isTokenExpired } from './httpUtils';
import InitVm from '../services/init';
import Logger from '../services/logger';


const MyLogger = new Logger(
    {
        lingerMs: 0
    },
    null
);

const client = axios.create({
    baseURL: window.env?.REACT_APP_API_URL
});

let isRefreshingToken = false;
let pendingRequests = [];

// Function to add subscribers for token refresh
const subscribeTokenRefresh = (callback) => {
    pendingRequests.push(callback);
};

// Notify all subscribers once a token is refreshed
const onTokenRefreshed = (newToken) => {
    pendingRequests.forEach((callback) => callback(newToken));
    pendingRequests = [];
};

// Function to refresh token
const handleTokenRefresh = async () => {
    isRefreshingToken = true;
    try {
        //get token
        const newToken = await new InitVm().refreshAccessToken();
        if (!newToken) {
            localStorage.clear();
            window.location = '#/login'
        }
        client.defaults.headers.Authorization = `Bearer ${newToken}`;
        onTokenRefreshed(newToken); // Notify subscribers
    } catch (err) {
        MyLogger.error('Token refresh failed:', err);
        localStorage.clear();
        window.location = '#/login';
    } finally {
        isRefreshingToken = false;
    }
};

const handleInterceptorRequestConfig = (config) => {
    checkAppVersion();
    if (isTokenExpired()) {
        if (!isRefreshingToken) {
            handleTokenRefresh();
        }
        // Wait for the token to be refreshed
        return new Promise((resolve) => {
            subscribeTokenRefresh((newToken) => {
                config.headers.Authorization = `Bearer ${newToken}`;
                resolve(config);
            });
        });
    }
    const AccessToken = localStorage.getItem('AccessToken');

    if (AccessToken) {
        config.headers['Authorization'] = 'Bearer ' + AccessToken;
    }

    if (config.method === 'put' && config.params && config.params.correlationId) {
        config.headers['x-correlation-id'] = config.params.correlationId;
    }
    return config;
}

// Interceptor to handle requests
client.interceptors.request.use(
    (config) => handleInterceptorRequestConfig(config),
    (error) => Promise.reject(error)
);

client.interceptors.response.use(
    (response) => handleInterceptorResponseRequest(response),
    (error) => handleInterceptorResponseError(error)
);

export default client;
